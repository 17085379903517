import React, { useEffect, useState } from "react";
import VerticalLayout from "../components/VerticalLayout";
import Header from "../components/Header";
import { Col, Container, FormFeedback, Input, Label, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer";
import { ScaleLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";
import ItemTrack from "../components/ItemTrack";
import { useProfile } from "../hooks/UserHooks";
import * as UserService from "../services/user";
import { useFormik } from "formik";
import * as Yup from "yup";

const Profile = () => {
  const { userProfile, updateProfile, defaultVolume } = useProfile();
  const navigate = useNavigate();

  const [hasMore, setHasMore] = useState(true);
  const [userProfileDynamique, setUserProfileDynamique] = useState(userProfile);
  const [volumeTrack, setVolumeTrack] = useState(defaultVolume);
  const [listFav, setListFav] = useState(userProfile.favories.slice(0, 5));
  const [selectedTrack, setSelectedTrack] = useState(null);

  /**
   * Ajout ou retirer des favories
   */
  const toggleFav = (track) => {
    if (userProfileDynamique) {
      let user = { ...userProfileDynamique };
      let isExist = user.favories.find((e) => e.id == track.id);
      if (isExist) {
        user.favories = user.favories.filter((e) => e.id != track.id);
      } else {
        user.favories.push(track);
      }
      setUserProfileDynamique(user);
      updateProfile(user);
    } else {
      navigate("/login");
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      lastname: userProfileDynamique && userProfileDynamique.lastname,
      firstname: userProfileDynamique && userProfileDynamique.firstname,
      email: userProfileDynamique && userProfileDynamique.email
    },
    validationSchema: Yup.object({
      email: Yup.string("Email invalide").email().required("Email obligatoire"),
      firstname: Yup.string().required("Prénom obligatoire"),
      lastname: Yup.string().required("Nom obligatoire")
    })
  });

  useEffect(() => {
    if (userProfile.favories.length > 5) {
      setHasMore(false);
    }
  }, []);

  return (
    <>
      <VerticalLayout />

      <div className="body">
        <div className="container content-body">
          <Header />
          <Container>
            <Row>
              <Col lg={12}>
                <div className="card m-2 p-4 bg-dark">
                  <div>
                    <h3>Profile</h3>
                    <Link to={"/logout"}>Déconnexion</Link>
                    <Col className="d-flex flex-column align-items-start">
                      <Label>Nom</Label>
                      <Input
                        type="text"
                        name="lastname"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lastname}
                        invalid={validation.errors.lastname}
                      />

                      {validation.errors.lastname && validation.touched.lastname && (
                        <FormFeedback
                          type="invalid"
                          style={{ display: "block" }}>
                          {validation.errors.lastname}
                        </FormFeedback>
                      )}
                    </Col>
                    <Col className="d-flex flex-column align-items-start">
                      <Label>Prénom</Label>
                      <Input
                        type="text"
                        name="firstname"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firstname}
                        invalid={validation.errors.firstname}
                      />
                      {validation.errors.firstname && validation.touched.firstname && (
                        <FormFeedback
                          type="invalid"
                          style={{ display: "block" }}>
                          {validation.errors.firstname}
                        </FormFeedback>
                      )}
                    </Col>
                    <Col className="d-flex flex-column align-items-start">
                      <Label>Email</Label>
                      <Input
                        type="email"
                        name="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email}
                        invalid={validation.errors.email}
                      />
                      {validation.errors.email && validation.touched.email && (
                        <FormFeedback
                          type="invalid"
                          style={{ display: "block" }}>
                          {validation.errors.email}
                        </FormFeedback>
                      )}
                    </Col>
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className="m-2">
                  <h3>favories</h3>

                  <InfiniteScroll
                    hasMore={hasMore}
                    style={{ width: "100%" }}
                    dataLength={listFav ? listFav.length : 0}
                    next={() => {
                      let nextData = userProfile.favories.slice(listFav.length, 5);

                      if (nextData.length) {
                        setListFav(() => [...listFav, ...nextData]);
                      } else {
                        setHasMore(false);
                      }
                    }}
                    loader={
                      <div className="m-5">
                        <ScaleLoader color="white" />
                      </div>
                    }>
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th style={{ width: "33%" }}></th>
                          <th style={{ width: "33%" }}></th>
                          <th style={{ minWidth: "33%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {listFav &&
                          listFav.map((track, i) => (
                            <ItemTrack
                              isFav={userProfileDynamique && userProfileDynamique?.favories?.find((e) => e.id == track.id)}
                              isPlay={selectedTrack?.id == track.id}
                              key={i}
                              toogleFav={() => toggleFav(track)}
                              onClick={() => setSelectedTrack(track)}
                              track={track}
                              volume={volumeTrack ? volumeTrack : 0}
                            />
                          ))}
                      </tbody>
                    </table>
                  </InfiniteScroll>
                </div>
              </Col>
            </Row>
          </Container>
          <Footer selectedTrack={selectedTrack} />
        </div>
      </div>
    </>
  );
};

export default Profile;
