import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Col } from "reactstrap";
import { FaMinus, FaRegHeart, FaRegPlayCircle } from "react-icons/fa";
import WavesurferPlayer, { useWavesurfer } from "@wavesurfer/react";
import { FaHeart, FaPlus, FaRegCirclePause } from "react-icons/fa6";
import { useProfile } from "../hooks/UserHooks";
import vinyle from "./../assets/images/vynyle.png";
import { Link } from "react-router-dom";
import { MdFileDownload } from "react-icons/md";
import { createPortal } from "react-dom";
import { addTrack, deleteTrack, trackList$ } from "../hooks/ListTrackHooks";
import { map } from "rxjs";

const ItemTrack = ({ onClick = () => {}, isPlay, track, isFav = false, toogleFav = () => {}, volume = 1, addToList = () => {} }) => {
  const [mediaElementState, setMediaElementState] = useState(null);
  const [inList, setInList] = useState(false);

  const containerRef = useRef(null);

  const { wavesurfer, isReady, isPlaying, currentTime } = useWavesurfer({
    container: containerRef,
    url: track.path,
    waveColor: "white",
    height: 35
  });

  const onPlayPause = () => {
    onClick();
    setTimeout(() => {
      wavesurfer && wavesurfer.playPause();
    }, 100);
  };

  useEffect(() => {
    if ((wavesurfer, isReady)) {
      setMediaElementState(wavesurfer.getMediaElement());
    }
  }, [wavesurfer, isReady]);

  useEffect(() => {
    trackList$.pipe(map((tracks) => tracks.findIndex((e) => e.id == track.id) != -1)).subscribe((isExist) => setInList(isExist));
  }, []);

  const ItemTrackFooter = useCallback(
    ({ isPlaying, isFav }) => {
      return (
        <div style={{ position: "fixed", display: "flex", bottom: 0, width: "100%", zIndex: 8000, alignItems: "center", height: "90px" }}>
          <div className="d-flex align-items-center w-100">
            <div className="px-3 w-100 d-flex align-items-center">
              <img
                className={isPlaying && "vinyle-img"}
                height={60}
                src={vinyle}></img>

              <p className="m-0 w-25">{track?.title}</p>
              <div className="d-flex">
                <span
                  onClick={() => onPlayPause()}
                  role="button"
                  className="hover-btn mx-2">
                  {isPlaying ? (
                    <FaRegCirclePause
                      fill={"#c91c1c"}
                      size={"25px"}
                    />
                  ) : (
                    <FaRegPlayCircle
                      fill={"#c91c1c"}
                      size={"25px"}
                    />
                  )}
                </span>
              </div>
              <div className="time">{wavesurfer && JSON.stringify(Math.round(wavesurfer.getDuration()) / 100).replace(".", ":")}</div>
              <div className="waveform mx-2 w-75">
                <WavesurferPlayer
                  media={mediaElementState}
                  height={35}
                  waveColor={"white"}
                  backend="MediaElement"
                />
              </div>
              <span
                onClick={toogleFav}
                role="button"
                className="hover-btn mx-2 position-relative">
                {isFav ? (
                  <FaHeart
                    fill="#c91c1c"
                    size={"25px"}
                  />
                ) : (
                  <FaRegHeart
                    fill="#c91c1c"
                    size={"25px"}
                  />
                )}
              </span>

              <Link
                onClick={() => window.open(track.path)}
                role="button"
                className="hover-btn mx-2 position-relative">
                <MdFileDownload
                  fill="#c91c1c"
                  size={"25px"}
                />
              </Link>
            </div>
          </div>
        </div>
      );
    },
    [mediaElementState, isFav]
  );

  return (
    <tr className={`item-track ${isPlay ? "active" : ""} p-2`}>
      <td>
        <div className="d-flex align-items-center">
          <img
            className={`vinyle ${isPlaying && "vinyle-img"}`}
            height={60}
            src={vinyle}></img>
          <span
            onClick={() => onPlayPause()}
            role="button"
            className="hover-btn mx-2">
            {isPlaying ? (
              <FaRegCirclePause
                fill={"#c91c1c"}
                size={"25px"}
              />
            ) : (
              <FaRegPlayCircle
                fill={"#c91c1c"}
                size={"25px"}
              />
            )}
          </span>
          <p className="m-0">{track?.title}</p>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="waveform mx-2 w-75">
            <div ref={containerRef} />
          </div>
          <div className="time">{wavesurfer && JSON.stringify(Math.round(wavesurfer.getDuration()) / 100).replace(".", ":")}</div>
        </div>
      </td>
      <td>
        <span
          onClick={toogleFav}
          role="button"
          className="flex-1 hover-btn mx-2 position-relative">
          {isFav ? (
            <FaHeart
              fill="#c91c1c"
              size={"25px"}
            />
          ) : (
            <FaRegHeart
              fill="#c91c1c"
              size={"25px"}
            />
          )}
        </span>

        <Link
          onClick={() => window.open(track.path)}
          role="button"
          className="flex-1 hover-btn mx-2 position-relative">
          <MdFileDownload
            fill="#c91c1c"
            size={"25px"}
          />
        </Link>
        {inList ? (
          <FaMinus
            className="icon-add-list"
            onClick={() => deleteTrack(track.id)}
          />
        ) : (
          <FaPlus
            onClick={() => addTrack(track)}
            className="icon-add-list"
          />
        )}
      </td>

      {isPlay &&
        createPortal(
          <ItemTrackFooter
            isPlaying={isPlaying}
            isFav={isFav}
          />,
          document.querySelector("footer")
        )}
    </tr>
  );
};

export default ItemTrack;
