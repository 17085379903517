import React from "react";
import Header from "../components/Header";
import { Footer } from "../components/Footer";
import { ToastContainer } from "react-toastify";
import VerticalLayout from "../components/VerticalLayout";
import { Container, Input, Form, label, Col, FormGroup, Button } from "reactstrap";
import {} from "react-router-dom";

const Contact = () => {
  //https://codepen.io/rkpasia/pen/LNEQod/
  return (
    <>
      <VerticalLayout />

      <div className="body mb-0">
        <Header />

        <ToastContainer />
        <div className="contact-form bg-main-container h-100">
          <div className="main-container h-100 scrollable">
            <Container className="width-form h-100 mt-4">
              <h3>Contactez-nous</h3>
              <Form className="mt-3 d-flex w-100 flex-column">
                <FormGroup className="text-start">
                  <Input
                    required
                    className="w-100"
                    id="nom"
                    name="nom"
                    type="text"
                  />
                  <label for="email">Nom</label>
                </FormGroup>

                <FormGroup className="text-start">
                  <Input
                    required
                    className="w-100"
                    id="prenom"
                    name="prenom"
                    type="text"
                  />
                  <label for="prenom">Prénom</label>
                </FormGroup>

                <FormGroup className="text-start">
                  <Input
                    required
                    className="w-100"
                    id="email"
                    name="email"
                    type="text"
                  />
                  <label for="Email">Email</label>
                </FormGroup>

                <FormGroup className="text-start ">
                  <Input
                    required
                    className="w-100"
                    id="message"
                    name="message"
                    type="text"
                  />
                  <label for="Message">Message</label>
                </FormGroup>
                <Button
                  color="light"
                  className="w-50 align-self-center mt-3">
                  Envoyer
                </Button>
              </Form>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
