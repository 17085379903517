import React from "react";
import { useEffect } from "react";
import { useProfile } from "../../hooks/UserHooks";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const { userProfile } = useProfile();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/login");
  }, []);

  return <div></div>;
};

export default Logout;
