import AbouUs from "../pages/AbouUs";
import CategoryAdmin from "../pages/admin/Category/Category.admin";
import CategoryFormAdmin from "../pages/admin/Category/CategoryForm.admin";
import MusicAdmin from "../pages/admin/Music/Music.admin";
import MusicFormAdmin from "../pages/admin/Music/MusicForm.admin";
import Login from "../pages/auth/Login";
import Logout from "../pages/auth/Logout";
import Register from "../pages/auth/Register";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import { NotFound404 } from "../pages/NotFound404";
import Profile from "../pages/Profile";

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/register", component: <Register /> },
  { path: "/about", component: <AbouUs /> },
  { path: "/contact", component: <Contact /> },
  { path: "/logout", component: <Logout /> },
  { path: "/", component: <Home /> },
  { path: "/admin/category", component: <CategoryAdmin /> },
  { path: "/not-found-404", component: <NotFound404 /> }
];

const connectedRoutes = [{ path: "/profile", component: <Profile /> }];

const adminRoutes = [
  { path: "/admin/category", component: <CategoryAdmin /> },
  { path: "/admin/category/form", component: <CategoryFormAdmin /> },
  { path: "/admin/music", component: <MusicAdmin /> },
  { path: "/admin/music/form", component: <MusicFormAdmin /> }
];

export { publicRoutes, adminRoutes, connectedRoutes };
