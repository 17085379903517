import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { FaBars, FaList } from "react-icons/fa";
import { FaMagnifyingGlass, FaX } from "react-icons/fa6";
import { trackList$ } from "../hooks/ListTrackHooks";
import ItemTrack from "./ItemTrack";
import * as MusicService from "../services/music";
import { toast } from "react-toastify";

const Header = () => {
  const [userLogged, setUserLogged] = useState(null);
  const [trackList, setTrackList] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [loading, setLoading] = useState(false);

  const downloadTracks = async () => {
    setLoading(true);

    try {
      // On envoie une requête POST pour récupérer le fichier ZIP
      let response = await MusicService.downloadTracks(trackList.map((t) => t.id));

      // Créer un lien pour télécharger le fichier
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "tracks.zip"; // Nom du fichier téléchargé
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      toast.error("Erreur lors du téléchargement des fichiers.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    setUserLogged(JSON.parse(user));
  }, []);

  useEffect(() => {
    trackList$.subscribe((newTrackList) => {
      setTrackList([...newTrackList]);
    });
  }, []);

  return (
    <div className="Header">
      <Row className="w-100">
        <Col
          xs={2}
          className="btn-offcanvas-menu">
          <a
            className="m-1 w-25"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample">
            <FaBars size={"30px"}></FaBars>
          </a>
        </Col>
        <Col
          lg={10}
          xs={8}>
          <div className="container-search">
            <input
              placeholder="Recherche"
              className="search-input"></input>
            <FaMagnifyingGlass
              size={"20px"}
              className=""
            />
          </div>
        </Col>
        <Col
          lg={2}
          xs={2}>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="position-relative">
              <FaList
                role="button"
                size={"25px"}
                onClick={() => setOpenList(() => !openList)}
              />
              {trackList.length ? <div className="dot-nb-list">{trackList.length}</div> : ""}
              <div className={`pop-list-track ${openList ? "open" : "close"}`}>
                <div className="d-flex w-100 p-2">
                  <div className="d-flex jutify-content-start mx-2">
                    {/* <button
                      onClick={() => downloadTracks()}
                      className="btn btn-color rounded-pill">
                      Télécharger
                    </button> */}
                  </div>
                  <div className="d-flex align-items-center justify-content-end mx-2 w-100">
                    <FaX
                      role="button"
                      onClick={() => setOpenList(false)}
                    />
                  </div>
                </div>
                <div className="mx-4 my-2 h-100">
                  <table
                    striped="true"
                    responsive="true"
                    className="w-100">
                    {trackList && trackList.length > 0 ? (
                      trackList.map((track) => (
                        <ItemTrack
                          key={track.id}
                          onClick={() => {}}
                          track={track}></ItemTrack>
                      ))
                    ) : (
                      <div className="mt-4">
                        <p className="text-secondary fst-italic">Aucune musique séléctionnée</p>
                      </div>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
