import React from "react";
import { Footer } from "../components/Footer";
import Header from "../components/Header";
import { ToastContainer } from "react-toastify";
import VerticalLayout from "../components/VerticalLayout";
import { Container } from "reactstrap";

const AbouUs = () => {
  return (
    <>
      <VerticalLayout />

      <div className="body mb-0">
        <Header />

        <ToastContainer />
        <div className="bg-main-container">
          <div className="main-container scrollable">
            <Container className="">
              <h3>Bienvenue sur music-sampling.fr : Votre source gratuite de musique, bruitages et sons</h3>
              <p>Nous sommes heureux de vous accueillir sur music-sampling.fr, un site dédié à offrir une vaste bibliothèque gratuite de sons, de musique et de bruitages pour enrichir vos projets audiovisuels. Que vous soyez réalisateur, créateur de contenu, développeur de jeux vidéo ou tout simplement un passionné de sonorités uniques, vous trouverez ici des ressources audio de qualité, accessibles gratuitement et prêtes à être utilisées dans vos créations.</p>
              <h3>Des créations gratuites pour vos projets</h3>
              <p>Sur music-sampling.fr, nous vous proposons des compositions originales et des bruitages divers, conçus avec soin et professionnalisme. Le site est entièrement gratuit, mettant à votre disposition une large gamme de sons, sans frais, pour répondre aux besoins des professionnels comme des amateurs. Tous les sons sont produits avec des équipements de haute qualité, garantissant un rendu sonore optimal et une grande diversité d'ambiances.</p>
              <h3>Une vaste collection de sons et de musique, 100% gratuits</h3>
              <p>
                Sur music-sampling.fr, vous trouverez un large choix de compositions musicales, de bruitages réalistes et d’effets sonores originaux, totalement gratuits. Des ambiances sonores pour vos films, des effets pour vos jeux vidéo, des musiques d'ambiance pour vos vidéos et podcasts, ainsi que des bruitages pour tout type de projet audiovisuel sont à votre disposition. Que vous cherchiez des sons de nature, des bruitages urbains, des effets futuristes ou des compositions musicales
                dynamiques, music-sampling.fr est la plateforme idéale pour accéder à des ressources de qualité sans aucun frais.
              </p>
              <h3>Une plateforme simple et accessible</h3>
              <p>Naviguer sur music-sampling.fr est une expérience fluide et agréable. La plateforme a été conçue pour vous permettre de trouver facilement le son que vous recherchez. Vous pouvez écouter les échantillons audio, explorer les catégories disponibles et télécharger gratuitement les sons sans inscription ou démarches compliquées. Les fichiers sont proposés dans des formats adaptés à tous vos besoins techniques, que ce soit pour une utilisation personnelle ou professionnelle.</p>
              <h3>Un service gratuit pour les professionnels et créateurs</h3>
              <p>
                music-sampling.fr souhaite offrir à tous, professionnels comme amateurs, la possibilité d’enrichir leurs projets sans contrainte budgétaire. En plus de la bibliothèque gratuite de sons et musiques, le site propose également des créations sur mesure pour des projets spécifiques. Si vous avez besoin de compositions originales ou de bruitages uniques, il vous suffit de nous contacter pour discuter de vos besoins. Chaque création est pensée pour correspondre parfaitement à l’ambiance
                de votre projet, et tout cela reste sans frais.
              </p>
              <h3>L'univers sonore de music-sampling.fr : Un voyage sensoriel libre et créatif</h3>
              <p>La musique et le son sont essentiels pour ajouter de la profondeur à vos œuvres. Grâce à son approche créative et professionnelle, music-sampling.fr met son savoir-faire au service de vos projets, en vous offrant une palette sonore riche et diversifiée, entièrement gratuite. Chaque composition est conçue pour vous aider à transmettre vos émotions et à sublimer vos visuels, tout en vous libérant des contraintes financières.</p>
              <h3>Une licence gratuite et flexible pour une utilisation libre</h3>
              <p>Tous les sons et musiques proposés sur music-sampling.fr sont accompagnés d'une licence d’utilisation gratuite. Que vous soyez un créateur de contenu sur YouTube, un développeur de jeux vidéo, ou que vous travailliez sur un projet commercial ou personnel, vous pourrez utiliser les créations de music-sampling.fr dans le respect des termes définis. La licence est claire et permet une utilisation libre sans coût, afin de vous offrir une grande flexibilité.</p>
              <h3>Rejoignez la communauté de music-sampling.fr</h3>
              <p>Restez informé des dernières créations, des mises à jour de la bibliothèque sonore et des nouvelles ressources gratuites en vous inscrivant à la newsletter de music-sampling.fr. Vous pouvez également suivre music-sampling.fr sur les réseaux sociaux pour découvrir ses dernières œuvres et bénéficier de conseils utiles sur l’utilisation de la musique et du son dans vos projets.</p>
              <h3>Contactez-nous</h3>
              <p>Vous avez une question ou une demande spécifique ? Vous souhaitez collaborer sur un projet ou obtenir des informations supplémentaires ? N’hésitez pas à nous contacter via le formulaire en ligne ou à envoyer un email directement à [adresse e-mail]. L'équipe de music-sampling.fr se fera un plaisir de répondre à vos questions et de vous accompagner dans vos projets sonores.</p>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default AbouUs;
