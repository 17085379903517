import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, Col, Container, Form, FormFeedback, Input, Label } from "reactstrap";
import { Footer } from "../../components/Footer";
import Header from "../../components/Header";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import * as UserService from "../../services/user";
import VerticalLayout from "../../components/VerticalLayout";

const Register = () => {
  const navigate = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      lastname: "",
      firstname: "",
      email: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: yup.object({
      lastname: yup.string().required("Nom obligatoire"),
      firstname: yup.string().required("Prénom obligatoire"),
      email: yup.string().email("Email invalide").required("Email obligatoire"),
      password: yup.string().required("Mot de passe obligatoire").min(6, "minimum 6 charactères"),
      confirmPassword: yup.string().when("password", (password, field) => (password ? field.required().oneOf([yup.ref("password")]) : field))
    }),
    onSubmit: (values) => {
      UserService.create(values)
        .then((res) => {
          navigate("/login");
        })
        .catch((err) => {
          console.log(err);

          toast.error(err.data.message);
        });
    }
  });
  console.log(validation.values);

  const handleError = () => {
    console.log("ofkeropgjkzrio");

    // toast.error("Veuillez remplir tous les champs");
  };
  console.log(validation.errors.email && validation.touched.email);

  return (
    <>
      <VerticalLayout />

      <div className="body mb-0">
        <Header />

        <ToastContainer />
        <div className="contact-form bg-main-container h-100">
          <div className="main-container scrollable">
            <Container className="width-form">
              <div className="rounded p-4">
                <h2 className="text-white mt-4">Inscription</h2>
                <Form
                  onError={handleError}
                  onSubmit={validation.handleSubmit}
                  className="mt-5">
                  <Col className="text-start m-3">
                    <Input
                      required
                      name="lastname"
                      invalid={validation.touched.lastname && validation.errors.lastname}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastname}
                    />
                    <label>Nom</label>
                  </Col>
                  <Col className="text-start  m-3">
                    <Input
                      required
                      name="firstname"
                      invalid={validation.touched.firstname && validation.errors.firstname}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstname}
                    />
                    <label>Prénom</label>
                  </Col>
                  <Col className="text-start m-3">
                    <Input
                      required
                      name="email"
                      invalid={validation.touched.email && validation.errors.email}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email}
                    />
                    <label>Email</label>
                  </Col>
                  <Col className="text-start m-3">
                    <Input
                      required
                      name="password"
                      invalid={validation.touched.password && validation.errors.password}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password}
                      type="password"
                    />
                    <label>Mot de passe</label>
                  </Col>
                  <Col className="text-start m-3">
                    <Input
                      required
                      name="confirmPassword"
                      invalid={validation.touched.confirmPassword && validation.errors.confirmPassword}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.confirmPassword}
                      type="password"
                    />
                    <label>Confimer mot de passe</label>
                  </Col>
                  <div className="d-flex m-2">
                    <Link
                      className="text-decoration-none"
                      to={"/register"}>
                      <p className="text-white">déjà inscrit ? connexion !</p>
                    </Link>
                  </div>
                  <Button
                    type="submit"
                    color="light">
                    S'inscrire
                  </Button>
                </Form>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
